import React, { Suspense, lazy, useState } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import Loader from './components/Loader'
import Joyride, { CallBackProps, StepProps } from 'react-joyride'
import { LocalStorageKeys } from './types/LocalStorageKeys'

const App = lazy(() => import('./App'))

const AppWrapper = () => {

  const [joyrideSteps, setJoyrideSteps] = useState([])
  const [joyrideHistory, setJoyrideHistory] = useState<CallBackProps>(null)
  const [enabledOverlay, setEnabledOverlay] = useState(true)
  const [runTour, setRunTour] = useState(false)

  const tour = {
    steps: joyrideSteps,
    history: joyrideHistory,
    enabledOverlay: enabledOverlay,
    isRunning: runTour,
    run: (value: boolean | ((prevState: boolean) => boolean)) => {
      setRunTour(value)
    },
    action: (steps: StepProps[]) => {
      setJoyrideSteps(steps)
    },
    enableOverlay: (value: boolean | ((prevState: boolean) => boolean)) => {
      setEnabledOverlay(value)
    }
  }

  const joyrideOptions = {
    locale: {
      close: 'Next',
      last: 'Finish'
    }
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    setJoyrideHistory(data)
    if (data.action === 'close' || data.action === 'skip' || data.action === 'reset') {
      setRunTour(false)
      localStorage.setItem(LocalStorageKeys.Tour, 'true')
    }
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader  absolutePosition={true}/>}>
        <App tour={tour} />
        {joyrideSteps?.length > 0 && <Joyride steps={joyrideSteps} scrollToFirstStep={true}  scrollDuration={450} locale={joyrideOptions.locale} continuous={true} disableOverlay={!enabledOverlay}
        callback={handleJoyrideCallback} run={runTour} showProgress={true} showSkipButton={true} hideBackButton={true} />}
      </Suspense>
    </BrowserRouter>
  )
}

ReactDOM.render(<AppWrapper />, document.getElementById('root'))