import React from 'react'
import '../styles/custom.css'

const Loader = ({ showLogo = false, absolutePosition }: { showLogo?: boolean, absolutePosition: boolean }) => {

  return <div className={`w-100 h-100 d-flex flex-column align-items-center justify-content-center ${absolutePosition && 'position-absolute'}`}>
    {showLogo && <svg style={{ width: '15rem', height: '10vh' }} width="312" height="25" viewBox="0 0 312 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_21)">
        <path d="M59.4241 0.545868V23.8783H53.6948L41.4275 6.44676V23.8783H36.7764V0.545868H42.7084L54.7729 17.679V0.545868H59.4241Z" fill="#171717" />
        <path d="M128.683 0.545868V23.8783H122.953L110.686 6.44676V23.8783H106.035V0.545868H111.967L124.031 17.679V0.545868H128.683Z" fill="#171717" />
        <path d="M161.409 0.545868L151.736 13.7124V23.8783H147.085V13.7466L137.379 0.545868H142.738L149.444 9.84604L156.083 0.545868H161.407H161.409Z" fill="#171717" />
        <path d="M199.257 0.545868V23.8783H194.605V6.54473L187.427 23.876H181.901L174.754 6.64498V23.876H170.103V0.545868H176.945L184.697 19.2442L192.449 0.545868H199.257Z" fill="#171717" />
        <path d="M210.651 0.545868H215.302V23.8783H210.651V0.545868Z" fill="#171717" />
        <path d="M229.693 16.0431C229.829 18.7429 232.119 20.1418 235.996 20.1418H236.535C240.175 20.1418 241.894 19.1097 241.894 17.4762C241.894 15.977 240.647 15.0771 237.579 14.5759L233.904 13.9766C228.445 13.0425 226.12 11.2426 226.12 7.10974C226.12 3.412 229.355 0.210938 236.13 0.210938C242.905 0.210938 246.577 3.14316 247.049 7.87754H242.163C241.859 5.6106 239.737 4.27777 236.399 4.27777H235.86C232.658 4.27777 230.806 5.37821 230.806 6.81128C230.806 8.41067 231.884 9.1443 234.547 9.57718L238.323 10.1764C243.817 11.0763 246.579 13.4754 246.579 17.3099C246.579 21.7754 242.907 24.2087 236.3 24.2087C229.189 24.2087 224.943 21.4428 224.807 16.0431H229.693Z" fill="#171717" />
        <path d="M82.7468 0.210949C82.7468 0.210949 82.7353 0.210949 82.7307 0.210949V4.27778H83.0163C87.6675 4.27778 91.4087 7.31025 91.4087 12.2109C91.4087 17.1117 87.6675 20.1441 83.0163 20.1441H82.4773C77.8607 20.1441 74.0526 17.1117 74.0526 12.2109H69.3669C69.2655 19.5107 75.0962 24.277 82.7468 24.2109C90.3629 24.277 96.1935 19.5449 96.0922 12.2109C96.1935 4.87698 90.3629 0.144877 82.7468 0.210949Z" fill="#171717" />
        <path d="M299.256 0.545868H287.965V4.6127H298.919C304.177 4.6127 306.906 7.24646 306.906 12.2132C306.906 17.18 304.177 19.8138 298.919 19.8138H287.965V23.8806H299.256C306.84 23.8806 311.59 19.5153 311.59 12.2155C311.59 4.91572 306.837 0.550425 299.256 0.550425V0.545868Z" fill="#171717" />
        <path d="M261.507 4.61042H265.069H277.719V0.545868H256.856V4.61042H261.507Z" fill="#171717" />
        <path d="M261.507 13.9106H269.686H275.696V9.84375H265.069H261.507H256.856V13.9106H261.507Z" fill="#171717" />
        <path d="M269.686 19.8092H261.507H256.856V23.876H278.055V19.8092H269.686Z" fill="#171717" />
        <path d="M17.4622 0.545868H12.06L9.71712 5.31215H14.6908L19.0771 14.3435H10.3069H5.27558L0.589844 23.876H5.71098L8.34181 18.4103H21.0237L22.0949 20.6431L23.6707 23.876H28.8978L17.4622 0.545868Z" fill="#171717" />
      </g>
      <defs>
        <clipPath id="clip0_1_21">
          <rect width="311" height="24" fill="white" transform="translate(0.589844 0.210938)" />
        </clipPath>
      </defs>
    </svg>}
    {typeof absolutePosition !== 'undefined' && <div className='loadingio-spinner-pulse-eexnqde6bvo'><div className='ldio-g4mc0st254'>
      <div></div><div></div><div></div>
    </div></div>}
  </div>
}

export default Loader